<template>
  <div class="af-panel" v-if="visible">
    <div class="af-panel__text mb-4">
      <b-icon
        icon-pack="fa"
        icon="eye-slash"
        size="is-large"
        type="is-danger"
        class="mr-2"
      ></b-icon>

      <div>
        <p class="is-size-5 has-text-weight-bold mb-2">
          {{ $t("af:offers.offers_are_hidden.title") }}
        </p>

        <span v-if="adjustedExists"
          ><strong>
            {{ $t("af:offers.offers_are_hidden.adjusted_only") }}
          </strong></span
        >
        <br />
        <span> {{ $t("af:offers.offers_are_hidden.no_result") }} </span>
        <div class="is-flex is-justify-content-end pt-5">
          <button v-if="adjustedExists && onShowAdjustedClick" class="button is-danger" @click="onShowAdjustedClick">{{ $t('af:offers.offers_are_hidden.show_adjusted.button') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OffersAreHidden",
  data() {
    return {
      visible: true,
    };
  },
  props: {
    adjustedExists: {
      type: Boolean,
      required: true,
    },
    onShowAdjustedClick: {
      type: Function,
      required: false,
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-panel {
  border-radius: 12px;
  padding: 20px;
  background: #fccbcb;
  border: 1px solid #f45252;

  &__text {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__actions {
    button,
    span {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 769px) {
  .af-panel {
    padding: 32px 36px;

    &__actions {
      button,
      span {
        width: unset;
      }
    }
  }
}
</style>
